// Here you can add other styles
.loginbg {
  background: url("../assets/img/connector_bg.jpg");
}
.loginLogoContainer {
  position: absolute;
  top: 10px;
  left: 175px;
}

.logOutLink {
  cursor: pointer;
}
